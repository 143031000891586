var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/v2/monitoring";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"stage.088ede98d17ec3d13fb3eca090fdc0ac90e1bceb"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/v2";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import {isFeatureFlagEnabled} from '@/utils/featureFlags'
import * as Sentry from '@sentry/nextjs'

Sentry.init({
  dsn: 'https://b7f19c9e1e6b48e6a5671875e7915020@sentry.tofisanext.com/16',

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,
  replaysOnErrorSampleRate: 0,
  replaysSessionSampleRate: 0,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,
  enabled: isFeatureFlagEnabled('SENTRY'),
})
